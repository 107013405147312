import React from "react";
import { Link } from "gatsby";

export function SocialSection() {
  return (
    <div className="mt-5">
      <a
        href="https://apps.apple.com/fr/app/setlist-live/id1558899571"
        className="button is-primary is-rounded is-medium mt-3 mr-3"
      >
        <span>Téléchargez pour iPhone</span>
        <span className="icon" role="img" aria-label="phone">
          📱
        </span>
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=com.lecocqconsulting.setlist"
        className="button is-primary is-rounded is-medium mt-3"
      >
        <span>Téléchargez pour Android</span>
        <span className="icon" role="img" aria-label="phone">
          📱
        </span>
      </a>

      <br />

      <a
        href="https://app.setlist.live"
        className="button is-primary is-rounded is-outlined mt-3"
      >
        <span>Continuer sur la version web</span>
      </a>

      <p className="mt-5">
        <Link to="/faq">
          <small>Besoin d'aide ?</small>
        </Link>
      </p>
    </div>
  );
}
