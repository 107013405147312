import React, { useEffect, useState } from "react";
import { Layout } from "../components/layouts/Layout";
import { SocialSection } from "../components/sections/social";
import { Nav } from "../components/layouts/Nav";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { Footer } from "../components/layouts/Footer";

export default function JoinPage() {
  const [inviteToken] = useQueryParam("token", withDefault(StringParam, ""));
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && inviteToken) {
      window.location.replace(`setlist://band-join/${inviteToken}`);
    }
  }, [inviteToken]);

  return (
    <Layout title="Rejoindre un groupe">
      <Nav />
      <section className="section">
        <div className="container content">
          <h1 className="title is-1">Rejoindre un groupe.</h1>

          <p>
            Pour rejoindre le groupe, téléchargez l'application sur votre
            téléphone, et créez vous un compte.
            <br />
            Si vous avez déjà un compte, connectez-vous sur l'application.
            <br />
            Revenez sur cette page pour être redirigé automatiquement sur
            l'application.
          </p>

          <form>
            <div className="field">
              <label className="label" htmlFor="inviteToken">
                Code d'invitation (cliquez pour copier){" "}
                {copied ? (
                  <span style={{ color: "green" }}>
                    <br />
                    Copié !
                  </span>
                ) : null}
              </label>
              <div className="control">
                <input
                  id="inviteToken"
                  className="input is-rounded is-medium"
                  type="text"
                  name="inviteToken"
                  defaultValue={inviteToken}
                  onClick={() => {
                    navigator.clipboard.writeText(inviteToken);
                    setCopied(true);
                  }}
                />
              </div>
            </div>
          </form>

          <SocialSection />
        </div>
      </section>
      <Footer />
    </Layout>
  );
}
